<template>
  <div class="min-h-full flex items-center justify-center p-5 select-none">
    <div class="mx-auto max-w-xs w-full">
      <div class="flex flex-col items-center justify-center">
        <UIcon
          name="i-heroicons-exclamation-triangle-20-solid"
          class="mb-2 w-20 h-20 text-4xl text-red-500"
        />
        <div class="mb-2 text-center text-lg font-medium">
          Ошибка {{ error.statusCode }}
        </div>
        <div class="mb-4 text-center text-base text-gray-500 dark:text-gray-400">
          {{ error.message }}
        </div>
        <UButton
          size="md"
          @click="handleError"
        >
          На главную
        </UButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  error: any
}
defineProps<Props>()

const router = useRouter()

const handleError = () => {
  router.push('/')
  clearError()
}

useHead({
  title: 'Ошибка'
})
</script>
