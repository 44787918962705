export default defineAppConfig({
  DEFAULT_LIMIT: '20',
  DEFAULT_LIMITS: ['20', '40', '80', '120'],
  FORMAT_DD_MM_YYYY_IN_HH_MM: 'DD.MM.YYYY в HH:mm',
  FORMAT_YYYY_MM_DD: 'YYYY-MM-DD',
  FORMAT_D_MMMM_YYYY_IN_HH_MM: 'D MMMM YYYY в HH:mm',
  FORMAT_D_MMMM_YYYY: 'D MMMM YYYY',
  FORMAT_DD_MM_YYYY: 'DD.MM.YYYY',
  FORMAT_YYYY: 'YYYY',
  FORMAT_M: 'M',
  FORMAT_HH_MM: 'HH:mm',
  FORMAT_YYYY_MM: 'YYYY-MM',
  FORMAT_MMMM: 'MMMM',
  FORMAT_DD_MM_YYYY_IN_HH_MM_SS: 'DD.MM.YYYY в HH:mm:ss',
  FORMAT_YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  FORMAT_MMMM_YYYY: 'MMMM YYYY',
  FORMAT_D_MMM_IN_HH_MM: 'D MMM в HH:mm',
  CALENDAR_WEEKS_SHORT: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
  CALENDAR_WEEKS: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  ui: {
    primary: 'sky',
    gray: 'zinc',
    tooltip: {
      base: 'h-auto'
    },
    card: {
      base: 'overflow-hidden',
      header: {
        padding: 'px-4 py-2 sm:px-6'
      },
      footer: {
        padding: 'px-4 py-2 sm:px-6'
      }
    },
    table: {
      th: {
        base: 'whitespace-nowrap'
      },
      checkbox: {
        padding: 'ps-4 w-12'
      }
    }
  }
})
